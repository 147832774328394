import * as _defineDataProperty2 from "define-data-property";
var _defineDataProperty = _defineDataProperty2;
try {
  if ("default" in _defineDataProperty2) _defineDataProperty = _defineDataProperty2.default;
} catch (e) {}
import * as _hasPropertyDescriptors2 from "has-property-descriptors";
var _hasPropertyDescriptors = _hasPropertyDescriptors2;
try {
  if ("default" in _hasPropertyDescriptors2) _hasPropertyDescriptors = _hasPropertyDescriptors2.default;
} catch (e) {}
import * as _functionsHaveNames2 from "functions-have-names";
var _functionsHaveNames = _functionsHaveNames2;
try {
  if ("default" in _functionsHaveNames2) _functionsHaveNames = _functionsHaveNames2.default;
} catch (e) {}
import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
var exports = {};
var define = _defineDataProperty;
var hasDescriptors = _hasPropertyDescriptors();
var functionsHaveConfigurableNames = _functionsHaveNames.functionsHaveConfigurableNames();
var $TypeError = _type;

/** @type {import('.')} */
exports = function setFunctionName(fn, name) {
  if (typeof fn !== "function") {
    throw new $TypeError("`fn` is not a function");
  }
  var loose = arguments.length > 2 && !!arguments[2];
  if (!loose || functionsHaveConfigurableNames) {
    if (hasDescriptors) {
      define( /** @type {Parameters<define>[0]} */fn, "name", name, true, true);
    } else {
      define( /** @type {Parameters<define>[0]} */fn, "name", name);
    }
  }
  return fn;
};
export default exports;